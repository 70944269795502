
import { defineComponent, computed, onBeforeMount } from 'vue';
// import { chatSocket } from '@/socket';

// Services
import { useUserStore } from '@/store/User';
import { useRouter } from 'vue-router';

// PrimeVue
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

// Composables
import { useViewport } from '@/submodules/shared-front/src/utils/composables/useViewport';

// Components
import MFooter from '@/components/utils/MFooter.vue';
import SideBar from '@/components/navigation/SideBar.vue';
import NavBar from '@/components/navigation/NavBar.vue';

const __default__ = defineComponent({
	name: 'App',
	components: {
		Toast,
		ConfirmDialog,
		SideBar,
		MFooter,
		NavBar
	},
	setup() {
		const userStore = useUserStore();
		const router = useRouter();

		const isLoggedIn = computed(() => userStore.loggedIn);
		const isLoadingApp = computed(() => userStore.isLoading);

		onBeforeMount(() => {
			// chatSocket.connect();
		});

		const sidebarWidth = computed(() => {
			if (!isLoggedIn.value || isMobile.value) return '0';
			return '75px';
		});
		const navbarHeight = computed(() => {
			return isLoggedIn.value ? '0' : '60px';
		});

		const navigateToSettings = async () => {
			router.push({ name: 'userProfileSettings' });
		};

		const logOut = async () => {
			userStore.logOut();
			router.push({ name: 'login' });
		};

		// Check mobile resolution
		const { isMobile } = useViewport();

		return {
			isLoggedIn,
			isLoadingApp,
			logOut,
			navigateToSettings,

			// View margins
			navbarHeight,
			sidebarWidth,

			// Resolution
			isMobile
		};
	}
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "430cd5da": (_ctx.navbarHeight),
  "68691766": (_ctx.sidebarWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__