import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Services
import { useUserStore } from '@/store/User';
import { useNotificationsStore } from '@/store/Notifications';

const routes: Array<RouteRecordRaw> = [
	// Home
	{ path: '/', name: 'home', redirect: { name: 'tournaments' } },

	// Tournaments
	{
		path: '/torneos',
		name: 'tournaments',
		component: () => import('@/views/tournaments/TournamentsView.vue')
	},
	{
		path: '/torneos/:tournamentUuid',
		props: true,
		name: 'tournament',
		component: () => import('@/views/tournaments/TournamentView.vue'),
		children: [
			{
				path: 'detalles',
				name: 'tournamentDetails',
				component: () =>
					import('@/components/tournaments/TournamentDetails.vue')
			},
			{
				path: 'equipos',
				name: 'tournamentTeams',
				component: () =>
					import('@/components/tournaments/TournamentTeams.vue')
			},
			{
				path: 'partidos',
				name: 'tournamentUserMatches',
				component: () =>
					import('@/components/tournaments/TournamentUserMatches.vue')
			},
			{
				path: 'enfrentamientos',
				name: 'tournamentLiveBrackets',
				component: () =>
					import(
						'@/components/tournaments/TournamentLiveBrackets.vue'
					)
			},
			{
				path: 'posiciones',
				name: 'tournamentPositions',
				component: () =>
					import('@/components/tournaments/TournamentPositions.vue')
			}
		]
	},

	// Matches
	{
		path: '/partidos',
		name: 'matches',
		component: () => import('@/views/matches/MatchesView.vue')
	},

	// Ranking
	{
		path: '/ranking/',
		name: 'ranking',
		component: () => import('@/views/ranking/RankingView.vue')
	},

	// Teams
	{
		path: '/equipos/:teamUuid',
		name: 'teamProfile',
		props: true,
		component: () => import('@/views/teams/TeamProfileView.vue')
	},
	{
		path: '/equipos/:teamUuid/invitaciones',
		name: 'teamPlayerInvitation',
		props: true,
		component: () => import('@/views/players/PlayerInvitationView.vue')
	},

	// Players
	{
		path: '/jugador/:playerUuid',
		name: 'playerProfile',
		props: true,
		component: () => import('@/views/players/PlayerProfileView.vue')
	},

	// Matches
	{
		path: '/partidos/:matchUuid',
		name: 'match',
		props: true,
		component: () => import('@/views/matches/MatchView.vue')
	},

	// Settings
	{
		path: '/ajustes',
		name: 'settings',
		props: false,
		component: () => import('@/views/settings/UserSettings.vue'),
		children: [
			{
				path: '/perfil',
				name: 'userProfileSettings',
				component: () =>
					import('@/components/settings/UserProfileSettings.vue')
			},
			{
				path: '/sociales',
				name: 'userSocialsSettings',
				component: () =>
					import('@/components/settings/UserSocialsSettings.vue')
			},
			{
				path: '/cuentas',
				name: 'userIntegrationsSettings',
				component: () =>
					import('@/components/settings/UserIntegrationsSettings.vue')
			}
		]
	},

	// Lobby
	{
		path: '/lobby',
		name: 'lobby',
		props: false,
		component: () => import('@/views/lobby/Lobby.vue')
	},

	// Authentication
	{
		path: '/login',
		name: 'login',
		props: false,
		component: () => import('@/views/auth/LoginView.vue')
	},
	{
		path: '/register',
		name: 'register',
		props: false,
		component: () => import('@/views/auth/RegisterView.vue')
	},
	{
		path: '/politicaDePrivacidad',
		name: 'privacyPolicy',
		props: false,
		component: () => import('@/views/legals/PrivacyPolicy.vue')
	},
	{
		path: '/terminosDeUso',
		name: 'termsOfService',
		props: false,
		component: () => import('@/views/legals/TermsOfService.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('@/views/auth/PathNotFoundView.vue')
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

const publicRoutes: string[] = [
	'tournaments',
	'tournamentDetails',
	'tournamentTeams',
	'tournamentUserMatches',
	'tournamentLiveBrackets',
	'tournamentPositions',
	'ranking',
	'playerProfile',
	'teamProfile',
	'termsOfService',
	'privacyPolicy',
	'lobby'
];

router.beforeEach(async (to, from, next) => {
	const userStore = useUserStore();
	const notificationsStore = useNotificationsStore();

	const queryAuthToken = to.query.authToken as string;
	const storedAuthToken = localStorage.getItem('auth-token');

	const authToken = queryAuthToken ? queryAuthToken : storedAuthToken;

	if (authToken && !userStore.loggedIn) {
		await userStore.setUser(authToken);
		await notificationsStore.fetchNotifications();
	}

	const isPublic =
		typeof to.name === 'string' && publicRoutes.includes(to.name);
	if (isPublic) return next();
	else if (
		userStore.loggedIn &&
		(to.path === '/login' || to.path === '/register')
	)
		next({ name: 'home' });
	else if (
		!userStore.loggedIn &&
		to.path !== '/login' &&
		to.path !== '/register'
	)
		next({ name: 'login' });

	return next();
});

export default router;
